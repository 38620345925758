import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ServiceModalPage } from "./../../pages/service-modal/service-modal.page";
import { Service } from "./../../interfaces/service";
import { Category } from "src/app/interfaces/category";
import { EnvironmentService } from "src/app/services/environment/environment.service";
import { Venue } from "src/app/interfaces/venue";
import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-services-list",
  templateUrl: "./services-list.component.html",
  styleUrls: ["./services-list.component.scss"],
})
export class ServicesListComponent implements OnInit {
  @Input() categoryName: string;
  @Input() venue: Venue;
  @Input() services: [];
  @Input() category: Category;

  on_mainApp: boolean;

  private modal: HTMLIonModalElement = null;

  constructor(
    private env: EnvironmentService,
    private modalController: ModalController,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    // console.log({services: this.services});
    this.on_mainApp = this.env.api.venue_id === null;
  }

  setActiveVenue(venue: Venue, event: MouseEvent) {
    const currentVenue = JSON.parse(localStorage.getItem("current-venue"));

    if (currentVenue === null) {
      localStorage.setItem("current-venue", JSON.stringify(venue));
    }

    localStorage.setItem("next-venue", JSON.stringify(venue));
  }

  async openModal(service: Service, bookNowBtn: HTMLAnchorElement) {
    const modalRef = this.modalService.open(ServiceModalPage);
    modalRef.componentInstance.service = service;

    // will resolve when the user closes the modal
    const result = await modalRef.result;

    if (result === "book-now") {
      bookNowBtn.click();
    }
  }
}
