import { AuthService } from 'src/app/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { NotyfService } from '../notyf/notyf.service';
import { INotyfOptions, NotyfNotification } from 'notyf';

@Injectable({
  providedIn: 'root'
})
export class TabsyncService {
  private notification;
  private notyf;
  private tabSync;

  constructor(
    private notyfService: NotyfService,
  ) { 
    try {
      const options: Partial<INotyfOptions> = {
        duration: 0,
        dismissible: false,
        position: {
          x: "right",
          y: "top",
        },
      };

      this.notyf = this.notyfService.newInstance(options);
      this.tabSync = new BroadcastChannel('tab_sync');

      this.tabSync.onmessage = (message: MessageEvent) => {
        if (this.notification instanceof NotyfNotification) {
          this.notyf.dismiss(this.notification);
        }

        if (message.data === 'logged in') {
          this.notification = this.notyf.success('You logged in with another tab or window. Please <a style="color: white; text-decoration: underline solid 1px white;" href="javascript:window.location.reload(true);">reload</a> your current page to refresh your session.')
        } else {
          this.notification = this.notyf.error('You have been logged out in another tab or window. Please <a style="color: white; text-decoration: underline solid 1px white;" href="javascript:window.location.reload(true);">reload</a> your current page to refresh your session.')
        }
      }
    } catch (error) {
      this.tabSync = {
        postMessage: message => {
         // console.log('broadcast message: ' + message);
        }
      }
    }
  }

  postMessage(message: any) {
    this.tabSync.postMessage(message)
  }
}
