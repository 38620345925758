import { SettingsService } from './../../services/settings/settings.service';
import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment/environment.service';

@Component({
  selector: 'app-hero-slider',
  templateUrl: './hero-slider.component.html',
  styleUrls: ['./hero-slider.component.scss'],
})
export class HeroSliderComponent implements OnInit {

  url: string;
  image_url: string;
  title: string;
  subtitle: string;

  constructor(
    private environment: EnvironmentService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.url = this.environment.api.url;
    this.image_url = this.environment.settings.venue.venue_banner;
    this.title = this.environment.settings.app.home_page_title;
    this.subtitle = this.environment.settings.app.home_page_subtitle;

    this.settingsService.settings$.subscribe(response => {
      this.image_url = response.venue.venue_banner;
      this.title = response.app?.home_page_title;
      this.subtitle = response.app?.home_page_subtitle;
    });
  }
}
