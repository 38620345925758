import { AuthService } from 'src/app/services/auth/auth.service';
import { CartService } from '../services/cart/cart.service';
import { AlertController, NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SingleVenueGuard implements CanActivate {
  constructor(
    private alertController: AlertController,
    private navController: NavController,
    private cartService: CartService,
    private auth: AuthService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>(async (resolve, reject) => {
      const cart = this.cartService.getCart();

      if (! localStorage.getItem('current-venue') || cart.length === 0) {
        resolve(true);
        return;
      }
      
      const current_venue = JSON.parse(localStorage.getItem('current-venue'));
      const next_venue_slug = next.params['slug'];
      let comparison;

      if (typeof next_venue_slug === 'undefined') {
        const next_venue = JSON.parse(localStorage.getItem('next-venue'));

        // console.log(JSON.parse(localStorage.getItem('next-venue')));
        
        comparison = (current_venue.id !== next_venue.id);
      } else {
        comparison = (current_venue.slug !== next_venue_slug);
      }
      
      if (comparison === true) {
        const alert = await this.alertController.create({
          header: 'Clear existing order?',
          message: 'You have already started an order with ' + current_venue.name + '. Do you want to clear this order and start a new one?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                alert.dismiss().then(() => { 
                  resolve(false); 
                });

                return false;
              }
            },
            {
              text: 'Clear and Proceed',
              role: 'proceed',
              handler: () => {
                this.auth.isLoggedIn().then((isLoggedIn: boolean) => {
                  if (isLoggedIn === true) {
                    this.cartService.clearCartExternal();
                  }

                  this.cartService.clearCart();
                  // localStorage.removeItem('current-venue');
                  localStorage.removeItem('in_use_voucher');
                  
                  alert.dismiss();
                });
                
                resolve(true);
                return true;
              }
            }
          ]
        });

        await alert.present();
      } else {
        resolve(true);
      }
    })
  }
}
