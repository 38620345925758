import { SettingsService } from 'src/app/services/settings/settings.service';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { Category } from 'src/app/interfaces/category';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EnvironmentService } from 'src/app/services/environment/environment.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  runtimePlatform: string;
  currentPlatform : string;

  appName: String = this.env.settings.app.app_brand_name;

  categories: Category[] = [];

  has_about_us_page: boolean = false;
  has_gallery_page: boolean = false;

  facebook_url: string = null;
  instagram_url: string = null;
  twitter_url: string = null;
  youtube_url: string = null;
  android_app_link: string = '#';
  ios_app_link: string = '#';
  on_mainApp: boolean = true;

  constructor(
    private platform: Platform,
    private categoriesService: CategoriesService,
    private settingsService: SettingsService,
    private env: EnvironmentService,
  ) { }

  async ngOnInit() {
    // this.platform.pause

    this.on_mainApp = this.env.api.venue_id === null;

    this.appName = this.env.settings.app.app_brand_name;
    
    this.runtimePlatform = this.platform.is('hybrid') || this.platform.is('mobile') ? 'mobile' : 'web';

    this.currentPlatform = await this.settingsService.getPlatform();

    // console.log(this.runtimePlatform,"                  currentPlatform    "+ this.currentPlatform);

    this.categoriesService.categories$.subscribe(categories => {
      this.categories = categories;
    })

    this.settingsService.settings$.subscribe(response => {
      this.has_about_us_page = (response.app?.has_about_us_page === 'yes');
      this.has_gallery_page = (response.app?.has_gallery_page === 'yes');

      if (response?.social_media?.venue_facebook_url) {
        this.facebook_url = response?.social_media?.venue_facebook_url
      }

      if (response?.social_media?.venue_instagram_url) {
        this.instagram_url = response.social_media.venue_instagram_url
      }

      if (response?.social_media?.venue_twitter_url) {
        this.twitter_url = response?.social_media?.venue_twitter_url
      }

      if (response?.social_media?.venue_youtube_url) {
        this.youtube_url = response?.social_media?.venue_youtube_url
      }

      if (response?.app?.ios_app_link) {
        this.ios_app_link = response?.app?.ios_app_link
      }

      if (response?.app?.android_app_link) {
        this.android_app_link = response?.app?.android_app_link
      }

    });
  }

  setActiveCategory(category: Category) {
    this.categoriesService.setActiveCategory(category);
  }
}
