import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, Inject, Renderer2} from '@angular/core';
import * as moment from 'moment';
import { NotyfService } from 'src/app/services/notyf/notyf.service';

export interface DPConfig{
  btnClasses? : string,
  navBtnClasses?: string,
  showDays?: boolean,
  dayFormat?: string,
  selectedItemClass?: string,
  selectedDateFormat?: string
}

@Component({
  selector: 'app-hrz-date-picker',
  templateUrl: './hrz-date-picker.component.html',
  styleUrls: ['./hrz-date-picker.component.scss'],
})


export class HrzDatePickerComponent implements OnInit, AfterViewInit,OnChanges {
  @ViewChild('hzDatePicker') hzDatePicker: ElementRef;
  @Input('dpConfig') dpConfig: DPConfig;
  @Output() onDateChange:EventEmitter<Date> = new EventEmitter();
  @Input('todayDate') today: Date;
  @Input('selectedDate') selectedDate: Date;
  currentDate:Date = new Date();
  todayInTime: number;
  viewLoaded: boolean = false;
  dayFormat: string;
  isCallInProgress: boolean = false;
  daysOfMonth: Array<any>;
  
  public MONTH_NAVS:any = {
    NEXT: 'next',
    PREV: 'previous'
  }
  public validDayFormats: Array<string> = ["E", "EEE", "EEEE"];
  currentMonth: any;
  currentDateInTime: number;
  
  constructor(
    private notyf: NotyfService,
    @Inject(DOCUMENT) document: Document,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }


  ngOnChanges(changes: SimpleChanges) {

    
    this.currentDateInTime = moment(moment(changes.selectedDate.currentValue).format('YYYY-MM-DD')).unix();
    this.currentDate = changes.selectedDate.currentValue
    //this.todayInTime = moment(moment(this.today).format('YYYY-MM-DD')).unix();
    this.selectedDate = changes.selectedDate.currentValue ?? new Date();
    this.updateDateBtns(changes.selectedDate.currentValue);
    this.setDefaultConfigValues();
    setTimeout(()=>{
      this.viewLoaded = true;
    },0);

    this.onDateChange.emit(this.selectedDate);
    
  }

  

  ngOnInit() {

    this.currentDate = new Date();
    this.selectedDate = this.selectedDate ?? new Date();
    this.todayInTime = moment(moment(this.today).format('YYYY-MM-DD')).unix();
    this.updateDateBtns(this.currentDate);
    this.setDefaultConfigValues();
    setTimeout(()=>{
      this.viewLoaded = true;
    },0);
  }

  ngAfterViewInit(){
    
  }

  /**
   * 
   * Sets the inital config properties to be used by the datepicker template
   */
  setDefaultConfigValues(){
    this.dpConfig = this.dpConfig || {};
    this.dpConfig.dayFormat = this.dpConfig.dayFormat ? this.dpConfig.dayFormat : 'E'; 
    if(this.validDayFormats.indexOf(this.dpConfig.dayFormat) == -1) {
      throw new Error("Invalid day format in dpConfig, supported formats are = " + this.validDayFormats.join());
    }
    this.dpConfig.selectedDateFormat = this.dpConfig.selectedDateFormat? this.dpConfig.selectedDateFormat : 'fullDate';
    this.dpConfig.selectedItemClass = this.dpConfig.selectedItemClass ? this.dpConfig.selectedItemClass + ' selected' : 'selected';
    this.dpConfig.showDays = true;
  }

  /**
   * 
   * This function is triggered when a date is clicked from the dates list
   * @param dayBtn 
   */
  onDateClick(dayBtn:any){

    // console.log(dayBtn)
    this.daysOfMonth.forEach((btn)=>{
      btn.isSelected = false;
    });
    dayBtn.isSelected = true;
    this.selectedDate = dayBtn.date;
    this.onDateChange.emit(this.selectedDate);
    
  }

  // get_total_scrollOffset(){

  //   let days = this.elementRef.nativeElement.querySelector('.days-inner');

  //   let container = this.elementRef.nativeElement.querySelector('.days-inner');
  //   let selectedClass = this.elementRef.nativeElement.querySelector('.selected');

  //   this.daysOfMonth.forEach((btn)=>{
  //     btn.isSelected = false;
  //   });

  //   if(selectedClass){

  //     console.log("container.offsetWidth", container.offsetWidth);
  //     const scrollTopValue = selectedClass.offsetLeft + (container.offsetWidth / 2);
  //     this.renderer.setProperty(container, 'scrollLeft', scrollTopValue);
  //   }


  // }

  /**
   * 
   * This function updates the buttons to display using the current date
   * @param date 
   */
  updateDateBtns(date?:Date){
    this.daysOfMonth = this.getDaysOfMonth(date);
    this.currentMonth = moment(date).format('MMM YYYY');
    this.isCallInProgress = true;
    setTimeout(()=>{
      this.isCallInProgress = false;
    },1500)
  }

  /**
   * 
   * This function is triggered when the user clicks on any of the next month nav button or previous month nav button
   * Fetches the next month's buttons from service and updates the buttons on view.
   * Also sets the same date of the next month as selected date
   * @param target 
   */
  onMonthNavClick(target:string){

    // console.log(this.currentDate, this.today.getMonth())

    let targetMonth: any;

    if(target == this.MONTH_NAVS.NEXT) {

      targetMonth = this.currentDate.getMonth() + 1 

    }else if( this.currentDate.getMonth() -1 < this.today.getMonth()){

      if( this.currentDate.getFullYear() <= this.today.getFullYear() ){

        // console.log(this.currentDate.getFullYear() , this.today.getFullYear())

        // console.log('Previous month is in past');

        this.notyf.error('Previous month is in past');

        return false;

      }else{

        targetMonth = this.currentDate.getMonth() -1;


      }

      

    }else{

      targetMonth = this.currentDate.getMonth() -1;

    }

    //let dateToSet = this.currentDate.getDate();
    let targetMonthDaysCount = this.daysInMonth(null, this.currentDate.getFullYear(), targetMonth);
    // if(dateToSet > targetMonthDaysCount){
    //   dateToSet = targetMonthDaysCount;
    // }
    //let neededDate = new Date(this.currentDate.getFullYear(), targetMonth, dateToSet);
    this.currentDate = new Date(this.currentDate.getFullYear(), targetMonth, 1);
    this.currentDateInTime = moment(moment(this.currentDate).format('YYYY-MM-DD')).unix(),
    this.updateDateBtns(this.currentDate);
    //this.selectedDate = this.daysOfMonth[neededDate.getDate()-1].date;
    this.onDateChange.emit(this.selectedDate);
  }


  
  /**
   * @author Ahsan Ayaz
   * This function returns an array of custom objects representing days information in the given month
   * @param dateObj{Date}
   * @returns {Array}
   */
  getDaysOfMonth(dateObj = new Date()): Array<any>{
    let days = [];
    let noOfDays = this.daysInMonth(dateObj);
    for(var i=1, len=noOfDays; i<=len; i++){
      let text = i.toString();
      // grab current date and check passed arguments.
      let date, month, year;
      date = i; //setting the date here
      if (!month || month === undefined) {
        month = dateObj.getMonth();
      }
      if (!year || year === undefined) {
        year = dateObj.getFullYear();
      }
      
    let currDate = new Date(year,month,date);
      days.push({
        dateInTime: moment(moment(currDate).format('YYYY-MM-DD')).unix(),
        text: text,
        date: currDate,
        isWeekend: (currDate.getDay() == 6 || currDate.getDay() == 0),
        //isSelected: dateObj.getDate() == i,
        isSelected: moment(moment(currDate).format('YYYY-MM-DD')).unix() == moment(moment(this.selectedDate).format('YYYY-MM-DD')).unix()
      });
    }
    return days;
  }

  /**
   * @author Ahsan Ayaz
   * Returns if the dateObject passed is an actual Date
   * @param dateObj {Date}
   * @returns {boolean} istDate value
   */
  isDate(dateObj:Date): boolean {
    var date:any = dateObj ? new Date(dateObj.toString()) : null;
    return (date !== null) && !isNaN(date) && (date.getDate() !== undefined);
  }


  /**
   * @author Ahsan Ayaz
   * Calculates the no of days in the given date or using given year and month
   * @param dateObj {Date}
   * @param year {number}
   * @param month {number}
   * @returns no of days {number}
   */
  daysInMonth(dateObj:Date, year?:number, month?:number): number {
    year = dateObj? dateObj.getFullYear(): year;
    month = dateObj? dateObj.getMonth(): month;
    let startDate:any = new Date(year, month, 1),
      endDate:any = new Date(year, month + 1, 1);
    let calc:any = (endDate - startDate) / (1000 * 60 * 60 * 24);
    return parseInt(calc, 10);
  }

  /**
   * @author Ahsan Ayaz
   * Calculates the no of weeks in the given date (month)
   * @param dateObj {Date}
   * @returns number of days {number}
   */
  weeksInMonth(dateObj:Date): number {
    let year = dateObj.getFullYear();
    let month = dateObj.getMonth();
    var firstOfMonth = new Date(year, month - 1, 1),
      lastOfMonth = new Date(year, month, 0),
      used = firstOfMonth.getDay() + lastOfMonth.getDate();
    return Math.ceil(used / 7);
  }

}
