import { SharedModule } from "src/app/shared/shared.module";
import { NgModule } from "@angular/core";

import { ServiceModalPageRoutingModule } from "./service-modal-routing.module";

import { ServiceModalPage } from "./service-modal.page";

@NgModule({
  imports: [SharedModule, ServiceModalPageRoutingModule],
  declarations: [ServiceModalPage],
})
export class ServiceModalPageModule {}
