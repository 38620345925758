import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { Category } from './../../interfaces/category';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private categories: Category[] = [];

  private active_category: Category = null;

  categoriesSubject: BehaviorSubject<Category[]>;
  categories$: Observable<Category[]>;

  active_category_subject: BehaviorSubject<Category>;
  active_category$: Observable<Category>;

  private latLng: {latitude: number|string, longitude: number|string} = {
    latitude: null,
    longitude: null
  };

  constructor(
    private api: ApiService,
    private env: EnvironmentService,
  ) {
    this.categoriesSubject = new BehaviorSubject<Category[]>(this.categories);
    this.categories$ = this.categoriesSubject.asObservable();

    this.active_category_subject = new BehaviorSubject<Category>(this.active_category);
    this.active_category$ = this.active_category_subject.asObservable();
    
    this.api.base_url.subscribe((url) => {
      if(url !== null) {
        this.fetchCategories();
      }
    });
  }

  async fetchCategories() {
    
    const response = await this.api.get('/categories').toPromise();

    if (response['success'] === true) {

      this.categories = response['data'];

      this.categoriesSubject.next(this.categories);

      return this.categories;
    }

    console.error('Error fetching categories', response);
  }

  async getCategories() {
    if (this.categories === null) {
      await this.fetchCategories();
    }

    return this.categories;
  }

  getActiveCategory() {
    return this.active_category;
  }

  setActiveCategory(category: Category|string) {
    this.fetchCategories().then(() => {
      if (typeof category === 'string') {
        category = this.categories.find((val: Category) => val.slug === category);
  
        if (typeof category === 'string' || typeof category === 'undefined') {
          return false;
        }
      }
  
      this.active_category = category;
      this.active_category_subject.next(this.active_category);
      localStorage.setItem('active-category', JSON.stringify(this.active_category));
    })
  }

  async getBySlug(slug: string) {
    if (this.categories.length === 0) {
      await this.fetchCategories();
    }

    let category: Category = null;
    
    this.categories.forEach(item => {
      if (item.slug === slug) {
        category = item;
        return false;
      }
    })

    return category;
  }
}
