import { ViewDidLeave } from '@ionic/angular';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { NotyfService } from './../../services/notyf/notyf.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Booking } from 'src/app/interfaces/booking';

const REFRESH_APPOINTMENTS_TIMEOUT = 5 * 60 * 1000; // 5 minutes

@Component({
  selector: 'app-available-appointments',
  templateUrl: './available-appointments.component.html',
  styleUrls: ['./available-appointments.component.scss'],
})
export class AvailableAppointmentsComponent implements OnInit, OnChanges, ViewDidLeave {

  @Input() venue_id: number = null;

  available_appointments: Booking[] = [];

  available_appointments_count = 0;

  available_appointment_index = 0;

  private appointments_sub;
  private appointments_timeout; 

  constructor(
    private api: ApiService,
    private env: EnvironmentService,
  ) { }

  ngOnInit() {
    this.refreshAvailableAppointments();
  }

  ngOnChanges() {
    this.refreshAvailableAppointments();
  }

  refreshAvailableAppointments() {
    if (this.env.api.venue_id === null && this.venue_id === null) {
      return;
    }

    let data = {};

    if (this.venue_id) {
      data['venue_id'] = this.venue_id;
    }

    if (this.appointments_sub) {
      this.appointments_sub.unsubscribe();
    }

    this.appointments_sub = this.api.get('/available-appointments', data).subscribe(
      response => {
        if (response['success']) {
          if (response['data'] != this.available_appointments) {
            this.available_appointments = response['data'];
            this.available_appointments_count = this.available_appointments.length;
          }

          this.appointments_sub.unsubscribe();
        }
      }
    );

    clearTimeout(this.appointments_timeout);

    this.appointments_timeout = setTimeout(() => this.refreshAvailableAppointments(), REFRESH_APPOINTMENTS_TIMEOUT);
  }

  previousAvailableAppointment() {
    this.available_appointment_index--;

    if (this.available_appointment_index < 0) {
      this.available_appointment_index = this.available_appointments_count - 1;
    }
  }

  nextAvailableAppointment() {
    this.available_appointment_index++;

    if (this.available_appointment_index >= this.available_appointments_count) {
      this.available_appointment_index = 0;
    }
  }

  ionViewDidLeave() {
    if (this.appointments_sub) {
      this.appointments_sub.unsubscribe();
    }
  }
}
