import { CategoriesService } from "src/app/services/categories/categories.service";
import { Category } from "../../interfaces/category";
import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { Venue } from "src/app/interfaces/venue";
import { FavouritesService } from "src/app/services/favourites/favourites.service";
import { NotyfService } from "src/app/services/notyf/notyf.service";

@Component({
  selector: "app-venue-list",
  templateUrl: "./venue-list.component.html",
  styleUrls: ["./venue-list.component.scss"],
})
export class VenueListComponent implements OnInit, OnChanges {
  @Input() venues: Venue[] = [];
  @Input('category') active_category: Category = null;
  @Input() no_results: string = "The selected category does not exist on any venue. Please select another one.";
  @Input() title: string = null;
  @Input() show_services: boolean = false;
  @Input() location_string: string = null;
  @Input() onHomePage: boolean = false;

  page_title: string;

  venueClasses = [];

  constructor(
    private favourites: FavouritesService,
    private notyf: NotyfService,
    private categoriesService: CategoriesService
  ) {}

  ngOnInit() {
    this.checkIfVenuesAreInFavourites();

    this.active_category = this.categoriesService.getActiveCategory();

    if (this.title !== null) {
      this.page_title = this.title;
    } else if (this.active_category !== null) {
      this.page_title = this.active_category?.name;
    }

  }

  ngOnChanges() {
    this.active_category = this.categoriesService.getActiveCategory();

    if (this.title !== null) {
      this.page_title = this.title;
    } else if (this.active_category !== null) {
      this.page_title = this.active_category?.name;
    }
  }

  toggleFavourite(favourite, type) {
    if (this.favourites.isInFavourites(favourite.id, type)) {
      this.favourites.removeFavourite(favourite, type);
      this.notyf.success(favourite.name + " was removed from your favourites");
    } else {
      this.favourites.addFavourite(favourite, type);
      this.notyf.success(favourite.name + " was added to your favourites");
    }

    this.checkIfVenuesAreInFavourites();
  }

  checkIfVenuesAreInFavourites() {
    if (this.venues.length > 0) {
      this.venues.forEach((venue) => {
        this.venueClasses.push({
          far: !this.favourites.isInFavourites(venue.id, "venues"),
          fas: this.favourites.isInFavourites(venue.id, "venues"),
          "text-danger": this.favourites.isInFavourites(venue.id, "venues"),
          "fa-heart": true,
        });
      });
    }
  }

  setActiveVenue(venue: Venue, event: MouseEvent) {
    const currentVenue = JSON.parse(localStorage.getItem("current-venue"));

    if (currentVenue === null) {
      localStorage.setItem("current-venue", JSON.stringify(venue));
    }

    localStorage.setItem("next-venue", JSON.stringify(venue));
  }
}
