import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private headers: any;

  app_version: string = "0.0.1";

  public base_url = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
  ) {
    // let headers = {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // };
    let headers = {};
    
    let url = localStorage.getItem('url');

    this.base_url.next(url);

    this.headers = new HttpHeaders(headers);
  }

  get(endpoint, params = {}, external = false) {

    if (!external) {

      endpoint = this.getFullUrl(endpoint);

    }

    return this.http.get(endpoint, {
      headers: this.headers,
      params
    });
  }
  
  post(endpoint, params = {}) {
    return this.http.post(this.getFullUrl(endpoint), params, {
      headers: this.headers
    });
  }

  delete(endpoint, params = {}) {
    return this.http.delete(this.getFullUrl(endpoint), {
      params,
      headers: this.headers,
    });
  }

  private getFullUrl(endpoint) {

    var clear_url_at = parseInt(localStorage.getItem('clear_url_at'));
    
    var now  = new Date().getTime();

    // console.log(clear_url_at + ' < ' + now);

    if( clear_url_at < new Date().getTime()){

      this.getApiUrl();

      // console.log('GET API URL');

    }

    if (endpoint.charAt(0) !== '/') {
      endpoint = '/' + endpoint;
    }

    let url = this.base_url.getValue();

    this.base_url.subscribe((value) => {
      url = value;
    });


    // console.log(url + endpoint);

    return url + endpoint;
  }

  getApiUrl(){

    this.get("https://api-check.development.communitysportsarenas.co.uk?version="+ encodeURIComponent(this.app_version), null, true).subscribe((url: string) => {

      localStorage.removeItem('url');
      localStorage.removeItem('clear_url_at');

      var now  = new Date().getTime();

      localStorage.setItem('url', url);
      localStorage.setItem('clear_url_at', (now + (1  * 24 * 60 * 60 * 1000)).toString());

      this.setBaseUrl(url);

    });

  }

  public setBaseUrl(url) {
    // console.log(url);
    this.base_url.next(url);
  }

}
