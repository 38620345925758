import { SettingsService } from 'src/app/services/settings/settings.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private appColour = '#1a4668';
  private appImage = '';
  private appTitle = '';
  private appDescription = '';
  private appKeywords = '';

  constructor(
    private environment: EnvironmentService,
    private meta: Meta,
    private title: Title,
    private settingsService: SettingsService,
  ) { 
    this.appColour = this.environment.settings.app.colour;
    this.appImage = this.environment.settings.venue.venue_logo;
    this.appTitle = this.environment.settings.app.seo_default_title || this.settingsService.getSetting('venue', 'venue_name');
    this.appDescription = this.environment.settings.app.seo_default_description || '';
    this.appKeywords = this.environment.settings.app.seo_default_keywords || '';
  }

  getPageMetaData(page_name: string): any {
    return this.settingsService.getSetting('pages', page_name);
  }

  setMetaData(config) {
    let description = this.appDescription;
    let image = this.appImage;
    let title = this.appTitle;
    let keywords = this.appKeywords;

    if (typeof config !== 'undefined') {
      description = typeof config.description !== 'undefined' ? config.description : this.appDescription;    
      image = typeof config.image !== 'undefined' ? config.image : this.appImage;  
      keywords = typeof config.key !== 'undefined' ? config.keywords : this.appKeywords;
      
      if (typeof config.title !== 'undefined') {
        title = config.title;
      } else if (typeof config.default_title !== 'undefined') {
        title = config.default_title + ' - ' + (this.environment.settings.app.seo_default_title || this.appTitle);
      } else {
        title = this.appTitle;
      }
    }

    this.title.setTitle(title) 

    // specify the tags to set for SEO and the content for the tag
    const tags = [
      { name: 'description', content: description },
      { name: 'theme-color', content: this.appColour },
      { name: 'keywords', content: keywords },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:image', content: image },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent' },
      { name: 'apple-mobile-web-app-title', content: title },
      { name: 'apple-touch-startup-image', content: image },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: image },
    ];

    tags.forEach(tag => this.meta.updateTag(tag));
  }
}
